import axios from 'axios'

const service = axios.create({
    // baseURL: 'https://mp.pencil-ic.cn/wx/', // api 的 base_url
    // baseURL:'https://search.ichelps.com/web/', // api 的 base_url
    baseURL: 'https://api.ichelps.com/', // api 的 base_url
    timeout: 60000
})
const API_ROOT = 'https://api.ichelps.com'
const STOCK_API = API_ROOT + '/stock'
const GOODS_API = API_ROOT + '/goods'

export function fetch(params) {
    // console.log(process.env.VUE_APP_BASE_API)
     return service.get("/goods/public/goods" ,{
         params
     })
}
export function matched(value) {
    //alert('云汉芯城')
     return service.get("/matched?part_number=" + value)
}

// 分页获取库存
export function pageStock(params) {
    return service.get(`${STOCK_API}/public/page-stock` ,{
        params
    })
}

// 根据产品ID 获取 库存
export function listStock(params) {
    return service.get(`${STOCK_API}/public/mall/list-stock`,{
        params
    })
}

// 根据产品ID 获取 爬虫 库存
export function spiderPrice(params) {
    return service.get(`${STOCK_API}/public/spider-price`,{
        params
    })
}

// 根据ID 获取详细信息
export function detailGoods(goodsId) {
    return service.get(`${GOODS_API}/public/goods/${goodsId}`)
}